import HomepageHeader from '@c/headers/homepage/HomepageHeader';
import MobileHero from '@c/headers/homepage/MobileHero';
import { DefaultLayout } from '@c/layouts';
import PopularCategories from '@c/PopularCategories';
import TopBrands from '@c/TopBrands';
import PageSectionHeader from '@ui/PageSectionHeader';
import Review from '@ui/Review';
import SafeImage from '@ui/SafeImage';
import { getYTVideosFromWidget, Video } from '@util/external/youtube';
import { getNBlogs } from '@util/firestore/blog';
import {
  getFeaturedProducts,
  getNewArrivals,
  getRecentlySold,
  getProducts,
} from '@util/firestore/products';
import { STATIC_REVIEWS } from '@util/firestore/reviews';
import { BlogDocument } from '@util/types/firestore/blog';
import { ProductDocument } from '@util/types/firestore/products';
import { useAuth } from 'context/AuthContext';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import useRealtimeAuctions from '@util/hooks/useRealtimeAuctions';
import useNewGearFocusModal from '@util/hooks/useNewGearFocusModal';
import Link from 'next/link';
import CEOMessage from '@c/headers/homepage/CEOMessage';
import { useMediaQuery } from '@mui/material';
import { useMemo, memo } from 'react';

// Dynamic Imports with prefetch:false for non-critical components
const NewGearFocusModal = dynamic(() => import('@c/modals/NewGearFocusModal'), {
  ssr: false,
});
const Carousel = dynamic(() => import('@ui/Carousel'), { 
  ssr: false,
});
const BlogLink = dynamic(() => import('@ui/Link/BlogLink'), { 
  ssr: false,
});
const ProductCard = dynamic(() => import('@c/cards/ProductCard'), {
  ssr: false,
});

// Memoized product card rendering function to prevent unnecessary re-renders
const MemoizedProductCard = memo(({ item, index }: { item: ProductDocument, index: number }) => (
  <ProductCard item={item} key={item.sku + '-' + index} />
));

// Add displayName to resolve linter error
MemoizedProductCard.displayName = 'MemoizedProductCard';

// Memoized function to render product cards
const renderProductCards = (productItems: ProductDocument[]) =>
  productItems.map((item, i) => (
    <MemoizedProductCard item={item} index={i} key={item.sku + '-' + i} />
  ));

type HomePageProps = {
  featuredProducts: ProductDocument[];
  newArrivals: ProductDocument[];
  recentlySold: ProductDocument[];
  videos: Video[];
  recentBlogPosts: BlogDocument[];
  dealsAndSteals: ProductDocument[];
};

function Home({
  featuredProducts,
  recentBlogPosts,
  dealsAndSteals,
}: HomePageProps) {
  const [showNewGearFocusModal, setShowNewGearFocusModal] =
    useNewGearFocusModal();
  const auctions = useRealtimeAuctions();
  const isMobile = useMediaQuery('(max-width:767px)');

  // Memoize rendered product cards to prevent re-renders
  const memoizedFeaturedCards = useMemo(() => 
    renderProductCards(featuredProducts), [featuredProducts]);
  
  const memoizedDealsCards = useMemo(() => 
    renderProductCards(dealsAndSteals), [dealsAndSteals]);

  // Only memoize auction cards if they exist
  const memoizedAuctionCards = useMemo(() => 
    auctions.realTimeProducts.length > 0 
      ? renderProductCards(auctions.realTimeProducts) 
      : [], 
    [auctions.realTimeProducts]);

  // Memoize reviews to prevent re-renders
  const memoizedReviews = useMemo(() => 
    STATIC_REVIEWS?.map((review) => (
      <Review
        key={review.id}
        img={review.img}
        name={review.name}
        content={review.content}
        googleReviewLink={review.googleReviewLink}
      />
    )), 
    []
  );

  return (
    <div className="w-full">
      {showNewGearFocusModal && (
        <NewGearFocusModal
          isOpen={showNewGearFocusModal}
          dismiss={() => {
            setShowNewGearFocusModal(false);
          }}
        />
      )}
      {isMobile ? (
        <>
          <TopBrands />
          <MobileHero />
        </>
      ) : <HomepageHeader />}
      <div className="mx-auto flex w-full max-w-default flex-col gap-[1rem] overflow-clip sm:mt-[2rem] sm:gap-[3rem] sm:px-default-padding-x px-4">
        <div className="hidden sm:block">
          <PageSectionHeader
            title="Top Brands"
            linkText="See All Brands"
            linkTitle="Browse Brands Such As Canon, Nikon, Sony And More!"
            href="/brands"
          />
          <TopBrands />
        </div>
        {auctions.realTimeProducts.length > 0 && (
          <div>
            <PageSectionHeader
              title="Current auctions"
              href="/auctions"
              linkText="See All"
            />
            <Carousel items={memoizedAuctionCards} />
          </div>
        )}
        <div>
          <PageSectionHeader
            title="Featured Listings"
            href="/featured"
            linkText="See All"
            linkTitle="Browse Our Featured New & Used Cameras And Lenses"
          />
          <Carousel items={memoizedFeaturedCards} />
        </div>
        <div>
          <PageSectionHeader title="Popular Categories" />
          <PopularCategories />
        </div>
        <div>
          <PageSectionHeader
            title="Deals and Steals"
            href="/deals-and-steals"
            linkTitle="Browse Our Best Deals on Camera Equipment"
            linkText="See All"
          />
          <Carousel items={memoizedDealsCards} />
        </div>
        <div className="h-[65.25vw] sm:h-[650px] w-full rounded-[1rem] overflow-hidden">
          <Link 
            href="https://www.youtube.com/watch?v=nqWLpsdBqWY"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SafeImage
              src="/youtube-preview.png"
              alt="GearFocus Introduction Video Preview"
              width={1920}
              height={1080}
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </Link>
        </div>
        <div className="flex flex-col gap-[1.6rem] bg-orange-50 sm:bg-transparent py-8 sm:py-0 mb-[2rem]">
          <h2 className="text-center text-[2.4rem] font-semibold text-orange-500 sm:text-inherit sm:text-h2 mt-[2rem]">
            See What Our Customers Say
          </h2>
          <Carousel
            incrementBy={1}
            items={memoizedReviews}
            showPaginator={false}
          />
        </div>
        <CEOMessage />
        <div className="px-4 sm:px-0">
          <PageSectionHeader
            title="From the GearFocus Blog"
            href="/blog"
          />
          <div className="flex gap-[2.4rem] overflow-x-auto">
            {recentBlogPosts.map((post) => (
              <BlogLink key={post.id} blog={post} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  // Fetch data in parallel to improve loading performance
  const [
    featuredProductsResponse,
    newArrivalsResponse,
    recentlySoldResponse,
    videosResponse,
    recentBlogPostsResponse,
    dealsAndStealsResponse,
  ] = await Promise.all([
    getFeaturedProducts(),
    getNewArrivals(),
    getRecentlySold(),
    getYTVideosFromWidget(),
    getNBlogs(4),
    getProducts({
      curated_list: 'deals-and-steals', limit: 12,
      keyword: null,
      sort: 'newest',
      page: 0,
      maxPrice: null,
      minPrice: null,
      conditions: [],
      condition: null,
      brand: null,
      brands: [],
      categories: [],
      category: null,
      last_slug: null,
      is_featured: false,
      is_expired: false,
      is_verified: false,
      is_draft: false,
      source: null,
      on_sale: false,
      is_auction: false,
      seller_id: null
    }),
  ]);

  const desc = 'Buy used cameras effortlessly with Gear Focus, a trusted marketplace for secure transactions of new and used camera gear. Sell and upgrade with assurance.';
  return {
    props: {
      featuredProducts: featuredProductsResponse.results,
      newArrivals: newArrivalsResponse.results,
      recentlySold: recentlySoldResponse.results,
      videos: videosResponse.videos,
      recentBlogPosts: recentBlogPostsResponse.results,
      dealsAndSteals: dealsAndStealsResponse.results,
      title: 'Buy and Sell Used Camera & Lenses With Gear Focus',
      metaTags: [
        {
          attributes: {
            property: 'og:title',
            content: 'Buy and Sell Used Camera & Lenses With Gear Focus',
          },
          key: 'og:title',
        },
        {
          attributes: {
            property: 'og:image',
            content: '/gear_pile.png',
          },
          key: 'og:image',
        },
        {
          attributes: {
            name: 'keywords',
            content: 'Used cameras for sale, Buy ussed cameras, Pre-owned DSLR cameras, Second-hand camera gear, Used photography equipment, Buy used camera lenses, Affordable used cameras, Used digital cameras, Used professional cameras, Second-hand photography gear, Used camera marketplace'
          },
          key: 'keywords',
        },
        {
          attributes: {
            name: 'description',
            content: desc,
          },
          key: 'description',
        },
        {
          attributes: {
            property: 'og:description',
            content: desc,
          },
          key: 'og:description',
        },
        {
          attributes: {
            property: 'og:type',
            content: 'website',
          },
          key: 'og:type',
        },
        {
          attributes: {
            property: 'og:site_name',
            content: 'Gear Focus, LLC.',
          },
          key: 'og:site_name',
        },
      ],
    },
    revalidate: 300,
  };
};

// Fix: Add the default export for the Home component
export default Home;

