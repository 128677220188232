import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const banners = [
  '/banners/mobile-banner-1.webp',
  '/banners/mobile-banner-2.webp',
  '/banners/mobile-banner-3.webp',
  '/banners/mobile-banner-4.webp',
  '/banners/mobile-banner-5.webp',
];

const MobileHero = () => {
  return (
    <div className="relative w-full h-[380px]">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="w-full h-full"
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={banner}>
            <div className="relative w-full h-full">
              <Image
                src={banner}
                alt={`Featured banner ${index + 1}`}
                fill
                className="object-cover min-h-[380px]"
                priority={index === 0}
              />
              <div className="absolute inset-0 flex flex-col items-center justify-end text-left px-4 bottom-[15px]">
                <Link
                  href="/products"
                  className="inline-block rounded-lg bg-[#E67E22] text-white text-[1.5rem] py-[8px] px-[15px] font-semibold"
                >
                  Shop Now
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MobileHero;